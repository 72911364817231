import { createContext, useContext } from "react";

export const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const initialState = {
  status: "",
  message: "",
  isActive: null,
  isMenuOpen: null,
  isDetailsPanelOpen: false,
};
