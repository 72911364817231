import { createBrowserRouter } from "react-router-dom";
import Public from "./Public";
import Protected from "./Protected";
import AdminProtected from "./AdminProtected";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Public />,
    children: [
      {
        path: "/login",
        lazy: async () => ({
          Component: (await import("pages/auth/login/Login")).default,
        }),
      },
      {
        path: "/register",
        lazy: async () => ({
          Component: (await import("pages/auth/register/Register")).default,
        }),
      },
      {
        path: "/forgot/password",
        lazy: async () => ({
          Component: (await import("pages/auth/forgot-password/ForgotPassword"))
            .default,
        }),
      },
      {
        path: "/reset/password",
        lazy: async () => ({
          Component: (await import("pages/auth/reset-password/ResetPassword"))
            .default,
        }),
      },
    ],
  },
  {
    path: "/",
    element: <Protected />,
    children: [
      {
        path: "/plays",
        lazy: async () => ({
          Component: (await import("pages/plays/Plays")).default,
        }),
      },
      {
        path: "/roi-matrix",
        lazy: async () => ({
          Component: (await import("pages/roi-matrix/RoiMatrix")).default,
        }),
      },
      {
        path: "/profile/settings",
        lazy: async () => ({
          Component: (await import("pages/settings/ProfileSettings")).default,
        }),
      },
      {
        path: "/roi/settings",
        lazy: async () => ({
          Component: (await import("pages/settings/RoiSettings")).default,
        }),
      },
      {
        path: "/treasury/calendar",
        lazy: async () => ({
          Component: (await import("pages/treasury-calendar/TreasuryCalendar"))
            .default,
        }),
      },
      // {
      //   path: "/data",
      //   lazy: async () => ({
      //     Component: (await import("pages/beta-mode/BetaMode")).default,
      //   }),
      // },
      // {
      //   path: "/stystems",
      //   lazy: async () => ({
      //     Component: (await import("pages/beta-mode/BetaMode")).default,
      //   }),
      // },
      {
        path: "/beta-mode",
        lazy: async () => ({
          Component: (await import("pages/beta-mode/BetaMode")).default,
        }),
      },
      {
        path: "*",
        lazy: async () => ({
          Component: (await import("pages/error/InnerError")).default,
        }),
      },

      // {
      //   path: "*",
      //   lazy: async () => ({
      //     Component: (await import("pages/error/InnerError")).default,
      //   }),
      // },
    ],
  },
  {
    path: "/",
    element: <AdminProtected />,
    children: [
      // Admin panel pages
      {
        index: true,
        path: "/",
        lazy: async () => ({
          Component: (await import("pages/admin/dashboard/Dashboard")).default,
        }),
      },
      {
        path: "/send/invite",
        lazy: async () => ({
          Component: (await import("pages/admin/invites/SendInvite")).default,
        }),
      },
      {
        path: "/credits",
        lazy: async () => ({
          Component: (await import("pages/admin/credits/Credits")).default,
        }),
      },
      {
        path: "/users",
        lazy: async () => ({
          Component: (await import("pages/admin/users/Users")).default,
        }),
      },
      {
        path: "*",
        lazy: async () => ({
          Component: (await import("pages/error/InnerError")).default,
        }),
      },
    ],
  },
  {
    path: "*",
    lazy: async () => ({
      Component: (await import("pages/error/Error")).default,
    }),
  },
]);

export default routes;
