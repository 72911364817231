import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { httpCookies } from "helpers/httpCookies";
import { ACCESS_TOKEN_KEY, ROLE_KEY } from "config/app.config";

const Protected = () => {
  const token = httpCookies.get(ACCESS_TOKEN_KEY);
  const role = httpCookies.get(ROLE_KEY);

  const location = useLocation();

  if (
    role &&
    token &&
    role !== "admin" &&
    (location.pathname.includes("roi-matrix") ||
      location.pathname.includes("systems") ||
      location.pathname.includes("data") ||
      location.pathname.includes("inbox"))
  ) {
    return <Navigate to="/beta-mode" />;
  }

  return token && role ? <Outlet /> : <Navigate to="/login" />;
};

export default Protected;
