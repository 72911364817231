import authService from "./services/auth.service";
import invitationService from "./services/invitation.service";
import settingsService from "./services/settings.service";
import userService from "./services/user.service";
import creditsService from "./services/credits.service";
import archiveService from "./services/archive.service";

const services = {
  auth: authService,
  invitation: invitationService,
  user: userService,
  settings: settingsService,
  credits: creditsService,
  archive: archiveService,
};

export default services;
