import axios from "axios";
import { extractErrorPayload } from "helpers/utils";

const archiveService = {
  all: (formData, cb) => {
    axios({
      url: "/money/movement/history",
      params: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
};

export default archiveService;
