import { APP_ACTIONS } from "./Actions";
import { initialState } from "./AppContext";

export const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_ACTIONS.TOGGLE_ALERT:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message,
      };
    case APP_ACTIONS.SET_ACTIVE:
      return {
        ...state,
        isActive: action.payload,
      };
    case APP_ACTIONS.TOGGLE_MOBILE_MENU:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      };
    case APP_ACTIONS.HIDE_MOBILE_MENU:
      return {
        ...state,
        isMenuOpen: null,
      };
    case APP_ACTIONS.SHOW_MOBILE_MENU:
      return {
        ...state,
        isMenuOpen: true,
      };
    case APP_ACTIONS.TOGGLE_DETAILS_PANEL:
      return {
        ...state,
        isDetailsPanelOpen: action.payload.isDetailsPanelOpen,
        isActive: action.payload.isActive,
      };

    default:
      return {
        ...state,
      };
  }
};
