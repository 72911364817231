import { v4 as uuid4 } from "uuid";
import deepEqual from "deep-equal";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // dependent on utc plugin
import timezone from "dayjs/plugin/timezone";
import isToday from "dayjs/plugin/isToday";
import CircleIcon from "../components/Icons/CircleIcon";
import StackIcon from "../components/Icons/StackIcon";
import { table } from "../theme/settings";
import { NOT_AVAILABLE, TIMEZONE } from "config/app.config";
import roiMatrixConfig from "config/roi.matrix.config";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.tz.setDefault(TIMEZONE);

export const uuid = () => uuid4();

export const getMockData = (dataFor = "tablet") => {
  if (dataFor === "desktop") {
    // prettier-ignore
    return {
      headers: [
        "Time/Score",
        "#",
        "Team",
        "Line",
        "$ Bet",
        "Cash %",
        "ROI",
        "Prob",
        "System",
        "Grade/U", 
      ],
      list: [
        {
          group: {
            icon: <StackIcon size={table.heading.icon.size} />,  
            name: "Watching",
            rows: [
              {
                id: uuid(),
                groups:[["01/29/23", 321, "49ers", '46.5', {bet: "$3.04M"}, "u77%", '$135K', "med", "nfl 12", 'B'],
                ['3:00 PM', 322, 'Eagles', '-2.5', {bet: "$3.04M"},'56%', '$135K', 'low', 'nfl 10', 'C' ],]
              },
              {
                id: uuid(),
                groups: [["01/29/23", 323, "Bengals", '48', {bet: "$3.32M"}, "59%", '$180K', "low", "", 'C'],
                ['6:30 PM', 324, 'Chiefs', '-1.5', {bet: "$3.32M"},'o74%','','med', 'nfl 10', 'B' ],]
              }
              
              
            ],
          },
        },
        {
          group: {
              icon: <CircleIcon size={table.heading.icon.size} />,  
            name: "Released",
            rows: [{ 
              id: uuid(), groups:[
              ["01/28/23", 525, "Knicks", '224', {bet: "$91K"}, "97%", '$21K', "", "", 'C'],
              ['3:00 PM', 526, 'Nets', 'pk', {bet: "$91K"},'u54%' ,'$21K','', 'nfl 10', 'B' ],
            ]}],
          },
        },
        {
          group: {
              icon: <StackIcon size={table.heading.icon.size} />,    
            name: "Current",
            rows: [{  id: uuid(), groups: [ ["01/28/23", 321, "49ers", '46.5', {bet: "$3.04M"}, "u77%", '$145K', "med", "nfl 12", 'B'],
            ['3:00 PM', 322, 'Eagles', '-2.5', {bet: "$3.04M"},'56%','$121K',  'low', 'nfl 10', 'C' ],]},
  
            {  id: uuid(), groups: [ ["01/29/23", 323, "Bengals", '48', {bet: "$3.32M"}, "59%", '$139K', "low", "", 'C'],
            ['6:30 PM', 324, 'Chiefs', '-1.5', {bet: "$3.32M"},'o74%','','med', 'nfl 10', 'B' ],] 
             
          }],
          },
        },
        {
          group: {
              icon: <StackIcon size={table.heading.icon.size} />,  
            name: "Final",
            rows: [
              {
                id: uuid(),
                groups: [ ["27", 315, "Bengals", '48', {bet: "$8.9M"}, "o72%",'$450K', "med", "nfl 13", '+5u'],
                ['10', 316, 'Bills', '-6', {bet: "$8.9M"},'50%','$416K','', '', '' ],]
              },
             {  id: uuid(),groups: [
              ["12", 317, "Cowboys", '48', {bet: "$10.53M"}, "o76%", '$551K', "med", "", 'C'],
              ['19', 318, '49ers', '-6', {bet: "$10.53M"},'53%','$614K','', '', '' ],
             ]
          }],
          },
        },
       
      ],
    };
  } else if (dataFor === "tablet") {
    // prettier-ignore
    return {
      headers: [
        "T/S",
        
        "Team",
        "Line",
        "$Bet",
        "Cash%",
        "ROI",
        // "Prob",
        "S",
        "G/U",
      ],
      list: [
        {
          group: {
            icon: <StackIcon size={table.heading.icon.size} />,  
            name: "Watching",
            rows: [
              {
                id: uuid(),
                groups:[["01/29", "med", "49ers", '46.5', {bet: "$3.04M"}, "u77%", '$135K',  "nfl 12", 'B'],
                ['3:00 PM', 'low', 'Eagles', '-2.5', {bet: "$3.04M"},'56%', '$135K',  'nfl 10', 'C' ],]
              },
              {
                id: uuid(),
                groups: [["01/29",  "low", "Bengals", '48', {bet: "$3.32M"}, "59%", '$180K', "", 'C'],
                ['6:30 PM', 'med', 'Chiefs', '-1.5', {bet: "$3.32M"},'o74%','', 'nfl 10', 'B' ],]
              }
              
              
            ],
          },
        },
        {
          group: {
              icon: <CircleIcon size={table.heading.icon.size} />,  
            name: "Released",
            rows: [{ 
              id: uuid(), groups:[
              ["01/28", "", "Knicks", '224', {bet: "$91K"}, "97%", '$21K',  "", 'C'],
              ['3:00 PM', '', 'Nets', 'pk', {bet: "$91K"},'u54%' ,'$21K', 'nfl 10', 'B' ],
            ]}],
          },
        },
        {
          group: {
              icon: <StackIcon size={table.heading.icon.size} />,    
            name: "Current",
            rows: [{  id: uuid(), groups: [ ["01/28", "med", "49ers", '46.5', {bet: "$3.04M"}, "u77%", '$145K',  "nfl 12", 'B'],
            ['3:00 PM', 'low', 'Eagles', '-2.5', {bet: "$3.04M"},'56%','$121K',   'nfl 10', 'C' ],]},
  
            {  id: uuid(), groups: [ ["01/29", "low", "Bengals", '48', {bet: "$3.32M"}, "59%", '$139K',  "", 'C'],
            ['6:30 PM', 'med', 'Chiefs', '-1.5', {bet: "$3.32M"},'o74%','', 'nfl 10', 'B' ],] 
             
          }],
          },
        },
        {
          group: {
              icon: <StackIcon size={table.heading.icon.size} />,  
            name: "Final",
            rows: [
              {
                id: uuid(),
                groups: [ ["27", "med", "Bengals", '48', {bet: "$8.9M"}, "o72%",'$450K',  "nfl 13", '+5u'],
                ['10', '', 'Bills', '-6', {bet: "$8.9M"},'50%','$416K', '', '' ],]
              },
             {  id: uuid(),groups: [
              ["12", "med", "Cowboys", '48', {bet: "$10.53M"}, "o76%", '$551K',  "", 'C'],
              ['19', '', '49ers', '-6', {bet: "$10.53M"},'53%','$614K','',  '' ],
             ]
          }],
          },
        },
       
      ],
    };
  } else if (dataFor === "mobile-lg") {
    // prettier-ignore
    return {
      headers: [
        "T/S",
       
        "Team",
        "Line",
        "$Bet",
        "Cash%",
        "ROI",
        "G/U",
      ],
      list: [
        {
          group: {
            icon: <StackIcon size={table.heading.icon.size} />,  
            name: "Watching",
            rows: [
              {
                id: uuid(),
                groups:[["01/29", "med", "49ers", '46.5', {bet: "3.04M"}, "u77%", '$135K',  'B'],
                ['3PM', 'low', 'Eagles', '-2.5', {bet: "3.04M"},'56%', '$135K',   'C' ],]
              },
              {
                id: uuid(),
                groups: [["01/29",  "low", "Bengals", '48', {bet: "3.32M"}, "59%", '$180K',  'C'],
                ['6:30PM', 'med', 'Chiefs', '-1.5', {bet: "3.32M"},'o74%','',  'B' ],]
              }
              
              
            ],
          },
        },
        {
          group: {
              icon: <CircleIcon size={table.heading.icon.size} />,  
            name: "Released",
            rows: [{ 
              id: uuid(), groups:[
              ["01/28", "", "Knicks", '224', {bet: "91K"}, "97%", '$21K',   'C'],
              ['3PM', '', 'Nets', 'pk', {bet: "91K"},'u54%' ,'$21K',  'B' ],
            ]}],
          },
        },
        {
          group: {
              icon: <StackIcon size={table.heading.icon.size} />,    
            name: "Current",
            rows: [{  id: uuid(), groups: [ ["01/28", "med", "49ers", '46.5', {bet: "3.04M"}, "u77%", '$145K',   'B'],
            ['3PM', 'low', 'Eagles', '-2.5', {bet: "3.04M"},'56%','$121K',    'C' ],]},
  
            {  id: uuid(), groups: [ ["01/29", "low", "Bengals", '48', {bet: "3.32M"}, "59%", '$139K',  'C'],
            ['6:30PM', 'med', 'Chiefs', '-1.5', {bet: "3.32M"},'o74%','',  'B' ],] 
             
          }],
          },
        },
        {
          group: {
              icon: <StackIcon size={table.heading.icon.size} />,  
            name: "Final",
            rows: [
              {
                id: uuid(),
                groups: [ ["27", "med", "Bengals", '48', {bet: "8.9M"}, "o72%",'$450K',  "nfl 13", '+5u'],
                ['10', '', 'Bills', '-6', {bet: "8.9M"},'50%','$416K', '', '' ],]
              },
             {  id: uuid(),groups: [
              ["12", "med", "Cowboys", '48', {bet: "10.53M"}, "o76%", '$551K',  "", 'C'],
              ['19', '', '49ers', '-6', {bet: "10.53M"},'53%','$614K','',  '' ],
             ]
          }],
          },
        },
       
      ],
    };
  }
};

export const viewPort = (onlyNumber = null) => {
  var myWidth = 0,
    myHeight = 0;
  if (typeof window.innerWidth == "number") {
    //Non-IE
    myWidth = window.innerWidth;
    myHeight = window.innerHeight;
  } else if (
    document.documentElement &&
    (document.documentElement.clientWidth ||
      document.documentElement.clientHeight)
  ) {
    //IE 6+ in 'standards compliant mode'
    myWidth = document.documentElement.clientWidth;
    myHeight = document.documentElement.clientHeight;
  } else if (
    document.body &&
    (document.body.clientWidth || document.body.clientHeight)
  ) {
    //IE 4 compatible
    myWidth = document.body.clientWidth;
    myHeight = document.body.clientHeight;
  }

  if (onlyNumber) {
    return {
      width: myWidth,
      height: myHeight,
    };
  }

  return {
    width: myWidth + "px",
    height: myHeight + "px",
  };
};

export const getTodaysFormattedDate = (format = "MM/DD/YY") => {
  return dayjs().format(format);
};

export const getFormattedDate = (timestamp = null, format = "MM/DD/YY") => {
  if (!timestamp || (timestamp && typeof timestamp === "undefined"))
    return null;

  return dayjs(timestamp).tz(TIMEZONE).format(format);
};

export const formatToCurrency = (num = 0) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  return formatter.format(num);
};

export const formatNumber = (num, digits = 2) => {
  // if (num >= 1000 && num < 10000) {
  //   return `$${Math.round(num)}K`;
  // }

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  if (item?.symbol === "M") {
    return item
      ? "$" + (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "";
  }

  return item
    ? "$" +
        Math.round((num / item.value).toFixed(digits).replace(rx, "$1")) +
        item.symbol
    : "";
};

export const truncate = (str, limit = 15, shouldAppendPeriods = true) => {
  if (typeof str === "undefined") return null;

  if (str && str.length < limit) {
    return str;
  }

  return shouldAppendPeriods
    ? `${str.substring(0, limit)}...`
    : str.substring(0, limit);
};

export const formatLine = (line, consensus) => {
  if (!line) return null;

  if (!consensus || (consensus && consensus.totalCash === 0)) return null;

  const tmpLine = cleanupLine(line);

  //console.log("actual: ", line, "tmpLine: ", tmpLine);

  // let encoded = encodeURI(line);

  let encoded = encodeURI(tmpLine);
  let decoded = decodeURIComponent(encoded);

  // console.log("Line: ", line, " tmpLine: ", tmpLine, " decoded: ", decoded);

  return decoded;

  // return decodeURI(encodeURI(line));

  // let pieces = line.split(";");

  // if (pieces.length === 1) {
  //   return pieces[0];
  // } else if (pieces.length === 2) {
  //   if (pieces[0] && pieces[1]) {
  //     return `${solveFraction(pieces[0])}${pieces[1]}`;
  //   } else {
  //     return solveFraction(pieces[0]);
  //   }
  // }

  // return line;
};

const cleanupLine = (line) => {
  let pieces = null;

  if (line.includes("+ev")) {
    pieces = line.split("+");

    if (pieces.length === 2) {
      if (pieces[0]) {
        return pieces[0];
      }

      return line;
    }
  } else if (line.includes("-ev")) {
    pieces = line.split("-");

    if (pieces.length === 2) {
      if (pieces[0]) {
        return pieces[0];
      }

      return line;
    }
  } else if (line.includes("-")) {
    pieces = line.split("-");

    if (pieces.length === 2) {
      if (pieces[0]) {
        return pieces[0];
      } else if (pieces[1] && pieces[1].includes("+")) {
        pieces = line.split("+");

        if (pieces[0]) {
          return pieces[0];
        }
      } else if (pieces[1] && pieces[1].includes("-")) {
        pieces = line.split("-");

        if (pieces[0]) {
          return pieces[0];
        }
      }

      return line;
    } else if (pieces.length === 3) {
      return `-${pieces[1]}`;
    }
  } else if (line.includes("+")) {
    pieces = line.split("+");

    if (pieces.length === 2) {
      if (pieces[0]) {
        return pieces[0];
      } else if (pieces[1] && pieces[1].includes("+")) {
        pieces = line.split("+");

        if (pieces[0]) {
          return pieces[0];
        }
      } else if (pieces[1] && pieces[1].includes("-")) {
        pieces = line.split("-");

        if (pieces[0]) {
          return pieces[0];
        }
      }
      return line;
    } else if (pieces.length === 3) {
      return `-${pieces[1]}`;
    }
  } else if (line.includes("u")) {
    pieces = line.split("u");

    if (pieces.length === 1) {
      return line;
    } else if (pieces.length === 2) {
      return pieces[0];
    }
  } else if (line.includes("o")) {
    pieces = line.split("o");

    if (pieces.length === 1) {
      return line;
    } else if (pieces.length === 2) {
      return pieces[0];
    }
  } else if (line === "pk") {
    return line;
  }

  return line;
};

// const solveFraction = (str) => {
//   let pieces = str.split("&frac");

//   if (pieces.length === 2) {
//     if (pieces[0] === "+" || pieces[0] === "-") {
//       return `${pieces[0]}${pieces[1][0] / pieces[1][1]}`;
//     } else {
//       return pieces[0] + pieces[1][0] / pieces[1][1];
//     }
//   }
// };

export const sortMatches = (origArray) => {
  // if (origArray.length <= 1) {
  // 	return origArray;
  // } else {

  // 	var left = [];
  // 	var right = [];
  // 	var newArray = [];
  // 	var pivot = origArray.pop();
  // 	var length = origArray.length;

  // 	for (var i = 0; i < length; i++) {
  // 		if (origArray[i] <= pivot) {
  // 			left.push(origArray[i]);
  // 		} else {
  // 			right.push(origArray[i]);
  // 		}
  // 	}

  // 	return newArray.concat(sortMatches(left), pivot, sortMatches(right));
  // }
  return origArray;
};

const getFilteredListByLeague = (list, leagueName) => {
  return list.filter((item) => item.group.leagueName === leagueName);
};

export const filterList = (payload, filter, date = null) => {
  const leagues = ["NBA", "NFL", "CFB", "CBB", "MLB", "NHL"];

  let finalList = [];

  if (leagues.includes(filter.name)) {
    finalList = payload.map((cat) => {
      return {
        ...cat,
        list: getFilteredListByLeague(cat.list, filter.name),
      };
    });
    return finalList;
  } else {
    if (filter.nickname === "top10") {
      const allowedIds = getAllowedTopTenIds(payload);

      finalList = payload.map((cat) => {
        return {
          ...cat,
          list: getFilteredListByIds(cat.list, allowedIds),
        };
      });
      return finalList;
    } else {
      // const allowedIds = getAllowedTopTenRoiIds(payload);

      const allowedIds = getAllowedTopTenNewRoiIds(payload);

      finalList = payload.map((cat) => {
        return {
          ...cat,
          list: getFilteredListByIds(cat.list, allowedIds),
        };
      });
      return finalList;
    }
  }

  //return [...payload];
};

export const isDateMatched = (groupDate, date) => {
  const format = "MM-DD-YY";

  let t1 = dayjs(groupDate).tz(TIMEZONE).format(format);
  let t2 = dayjs(date).tz(TIMEZONE).format(format);

  //const diff = t1.diff(t2, "day");

  return t1 === t2;

  // if (t1 === t2) {
  //   return true;
  // }
  // return false;
};

const getFileteredListByDate = (list, date) => {
  const finalList = [];

  for (const l of list) {
    finalList.push({
      group: l.group,
      matches: l.matches.filter((item) =>
        isDateMatched(item.startDateTime, date)
      ),
    });
  }

  return finalList;
};

export const filterListByDate = (list, date) => {
  let finalList = [];

  for (const l of list) {
    finalList.push({
      ...l,
      list: getFileteredListByDate(l.list, date),
    });
  }

  return finalList;
};

const getFilteredListByIds = (list, allowedIds) => {
  return list
    .map((item) => {
      const matches = item.matches.filter((match) =>
        allowedIds.includes(match.id)
      );

      if (matches.length) {
        return {
          group: item.group,
          matches,
        };
      }
      return null;
    })
    .filter((a) => a);
};

export const getAllowedTopTenIds = (items) => {
  let tempList = [];

  for (let item of items[0].list) {
    for (let inner of item.matches) {
      if (inner.consensus && inner.consensus.totalCash) {
        tempList.push({
          id: inner.id,
          bet: inner.consensus.totalCash,
        });
      }
    }
  }

  // for (let item of items[1].list) {
  //   for (let inner of item.matches) {
  //     if (inner.consensus && inner.consensus.totalCash) {
  //       tempList.push({
  //         id: inner.id,
  //         bet: inner.consensus.totalCash,
  //       });
  //     }
  //   }
  // }

  // for (let item of items[2].list) {
  //   for (let inner of item.matches) {
  //     if (inner.consensus && inner.consensus.totalCash) {
  //       tempList.push({
  //         id: inner.id,
  //         bet: inner.consensus.totalCash,
  //       });
  //     }
  //   }
  // }

  const list = tempList
    .sort((a, b) => b.bet - a.bet)
    .slice(0, 10)
    .map((a) => a.id);

  return list;
};

export const getAllowedTopTenRoiIds = (items) => {
  let tempList = [];

  let roi = null;

  for (let item of items[0].list) {
    for (let inner of item.matches) {
      if (inner.roi) {
        roi = inner.roi.away ? inner.roi.away : inner.roi.home;

        if (roi) {
          tempList.push({
            id: inner.id,
            roi: inner.roi.away ? inner.roi.away : inner.roi.home,
          });
        }

        roi = null;
      }
    }
  }

  const list = tempList
    .sort((a, b) => b.roi - a.roi)
    .slice(0, 10)
    .map((a) => a.id);

  return list;
};

export const getAllowedTopTenNewRoiIds = (items) => {
  const roiList = [];

  for (let item of items[0].list) {
    for (let inner of item.matches) {
      roiList.push({
        eventId: inner.id,
        groupId: item.group.id,
        roi: Math.abs(
          inner.awayTeamArchive.side.cash - inner.homeTeamArchive.side.cash
        ),
      });
      roiList.push({
        eventId: inner.id,
        groupId: item.group.id,
        roi: Math.abs(
          inner.awayTeamArchive.total.cash - inner.homeTeamArchive.total.cash
        ),
      });
      roiList.push({
        eventId: inner.id,
        groupId: item.group.id,
        roi: Math.abs(
          inner.awayTeamArchive.ml.cash - inner.homeTeamArchive.ml.cash
        ),
      });
    }
  }

  return roiList
    .sort((a, b) => b.roi - a.roi)
    .slice(0, 10)
    .map((a) => a.eventId);
};

export const cleanupValue = (str) => {
  if (!str) return "";

  const pieces = str.split(";");

  if (pieces.length === 1) {
    return str;
  } else if (pieces.length === 2) {
    if (pieces[1].length) {
      return `${pieces[0]};`;
    }
    return str;
  }
};

// export const mergeUpdates = (list, updates) => {
//   return list.map((item) => {
//     return item.list.map((innerList) => {
//       return innerList.matches.map((event) => applyUpdates(event, updates));
//     });
//   });
// };

// const applyUpdates = (event, updates) => {
//   for (let update of updates) {
//     if (update.id === event.id) {
//       if (update.type === "odd") {
//         event.odds = {
//           cssClass: update.cssClass,
//           currentAwayValue: cleanupValue(update.CurrentAwayValue),
//           currentHomeValue: cleanupValue(update.CurrentHomeValue),
//           currentAwayPoints: update.CurrentAwayPoints,
//           currentHomePoints: update.CurrentHomePoints,
//         };
//       } else if (update.type === "consensus") {
//         event.consensus = {
//           totalCash: update.AllCash,
//           totalCashAwayPercentage: update.AllCashAwayPercentage,
//           totalCashHomePercentage: update.AllCashHomePercentage,
//         };

//         if (event.consensus && event.consensus.totalCash) {
//           const percentage = event.consensus.totalCashAwayPercentage - 0.5;
//           event.roi = event.consensus.totalCash * percentage;
//         }
//       }
//     }
//   }

//   return event;
// };

export const getLatestBetAmount = (match, updates = null) => {
  if (!updates) {
    if (!match.consensus) return null;

    return "$" + formatNumber(match.consensus?.totalCash);
  }

  if (!match.consensus?.totalCash) {
    return null;
  }

  for (let update of updates) {
    if (update.id === match.id) {
      if (update.consensus) {
        return "$" + formatNumber(update?.consensus?.totalCash);
      }
    }
  }

  return "$" + formatNumber(match.consensus?.totalCash);
};

export const getUpdatedMatch = (match, updates) => {
  if (!match) return null;

  if (!updates.length) return { match, updated: false };

  let finalMatch = match;

  for (let update of updates) {
    if (update.id === match.id) {
      const isEqual = deepEqual(match, update, { strict: true });

      // const isEqual =
      //   typeof update.isUpdated !== "undefined" && update.isUpdated
      //     ? true
      //     : false;

      return !isEqual
        ? {
            //match: { ...update, leagueShortName: match.leagueShortName },
            match: update,
            updated: true,
          }
        : { match, updated: false };
    }
  }

  return { match: finalMatch, updated: false };
};

// Original Version
// export const getRoi = (match, option) => {
//   console.log(match);

//   if (!match.roi) return null;

//   if (option === "away") {
//     if (
//       match.consensus?.totalCashHomePercentage <
//       match.consensus?.totalCashAwayPercentage
//     ) {
//       if (
//         match.consensus.totalCashAwayPercentageValue.includes("u") ||
//         match.consensus.totalCashAwayPercentageValue.includes("o")
//       ) {
//         return null;
//       }
//       return formatNumber(match.roi);
//     } else {
//       if (
//         match.consensus.totalCashHomePercentageValue.includes("u") ||
//         match.consensus.totalCashHomePercentageValue.includes("o")
//       ) {
//         return formatNumber(match.roi);
//       }
//     }
//   } else if (option === "home") {
//     if (
//       match.consensus?.totalCashHomePercentage >
//       match.consensus?.totalCashAwayPercentage
//     ) {
//       if (
//         match.consensus.totalCashHomePercentageValue.includes("u") ||
//         match.consensus.totalCashHomePercentageValue.includes("o")
//       ) {
//         return null;
//       }

//       return formatNumber(match.roi);
//     } else {
//       if (
//         match.consensus.totalCashAwayPercentageValue.includes("u") ||
//         match.consensus.totalCashAwayPercentageValue.includes("o")
//       ) {
//         return formatNumber(match.roi);
//       }
//     }
//   }
// };

export const getRoi = (match, option) => {
  if (!match) return null;

  if (option === "away") {
    return formatNumber(match.roi.away);
  } else if (option === "home") {
    return formatNumber(match.roi.home);
  }
};

export const encrypt = (salt, text) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};

export const decrypt = (salt, encoded) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};

export const extractErrorPayload = (error) => {
  if (typeof error.response?.data?.errors !== "undefined") {
    let message = "";
    for (const key in error.response.data.errors) {
      message = error.response.data.errors[key][0];
      break;
    }

    return {
      status: "error",
      message: message,
      statusCode: error.response.status,
    };
  }
  return {
    statusCode: error?.response?.status,
    status: error?.response?.data?.status,
    message: error?.response?.data?.message,
  };
};

export const isValidFileSize = (bytes, fileSizeThreshold = 5) => {
  const size = bytes / (1024 * 1024);
  if (size < fileSizeThreshold) {
    return true;
  }
  return false;
};

export const isValidFile = (
  file,
  extensions = ["png", "jpeg", "jpg", "webm", "bmp"]
) => {
  const ext = file.split(".").pop().toLowerCase();

  if (extensions.includes(ext)) {
    return true;
  }
  return false;
};

export const getObjectUrl = (file = null) => {
  if (!file) return null;
  return URL.createObjectURL(file);
};

export const extractNumber = (str = "") => {
  if (!str) return "";

  const matches = str.match(/(\d+)/);

  if (matches) {
    return matches[0];
  }

  return "";
};

export const getFormattedRoiMatrixUnitSize = (unitSize = "") => {
  if (!unitSize) return NOT_AVAILABLE;

  return `$${unitSize}`;
};

export const getLeagueRanges = (item) => {
  const groups = roiMatrixConfig.groups;

  let league = null;

  for (const group of groups) {
    if (group.label === item.leagueShortName) {
      league = group;
      break;
    }
  }

  return league;
};

export const getRawRoi = (match, option) => {
  if (option === "away") {
    return match.roi.away;
  } else if (option === "home") {
    return match.roi.home;
  }
};

export const getRoiMatirxMinMaxRanges = (item, ranges) => {
  const reversedRanges = ranges.reverse();

  const roiAway = getRawRoi(item, "away");
  const roiHome = getRawRoi(item, "home");

  const roi = roiAway ? roiAway : roiHome;

  if (roi) {
    for (const r of reversedRanges) {
      if (r) {
        if (roi >= r.min && roi <= r.max) {
          return {
            roi,
            range: {
              ...r,
            },
          };
        }
      }
    }

    // while (true) {
    //   if (
    //     typeof reversedRanges[i] !== "undefined" &&
    //     reversedRanges[i] &&
    //     roi >= reversedRanges[i].min &&
    //     roi <= reversedRanges[i].max
    //   ) {
    //     return {
    //       roi,
    //       range: {
    //         min: reversedRanges[i].min,
    //         max: reversedRanges[i].max,
    //         minLabel: reversedRanges[i].minLabel,
    //         maxLabel: reversedRanges[i].maxLabel,
    //         minGrade: roiMatrixConfig.headers.grades[i].label,
    //         maxGrade: roiMatrixConfig.headers.grades[i].label,
    //       },
    //     };
    //   } else if (
    //     typeof reversedRanges[i] !== "undefined" &&
    //     typeof reversedRanges[j] !== "undefined" &&
    //     reversedRanges[i] &&
    //     reversedRanges[j] &&
    //     roi >= reversedRanges[i].max &&
    //     roi <= reversedRanges[j].min
    //   ) {
    //     return {
    //       roi,
    //       range: {
    //         min: reversedRanges[i].max,
    //         max: reversedRanges[j].min,
    //         minLabel: reversedRanges[i].minLabel,
    //         maxLabel: reversedRanges[j].maxLabel,
    //         minGrade: roiMatrixConfig.headers.grades[i].label,
    //         maxGrade: roiMatrixConfig.headers.grades[j].label,
    //       },
    //     };
    //   }
    //   i++;
    //   j++;
    // }
  }
  return null;
};

// const createMaxRangeWithDifference = (num) => {
//   let result = 0;

//   const numLength = `${Math.round(num)}`.length;
//   // num = parseInt(num);

//   let pieces = null;
//   let floatingPointNumber = 0;

//   if (numLength === 3) {
//     floatingPointNumber = num / 100;

//     floatingPointNumber = floatingPointNumber.toString();

//     if (floatingPointNumber.includes(".")) {
//       pieces = floatingPointNumber.split(".");
//       result = `${pieces[0]}.99`;

//       result = Number(result) * 100;
//     } else {
//       result = num;
//     }
//   } else if (numLength === 4) {
//     floatingPointNumber = num / 1000;

//     floatingPointNumber = floatingPointNumber.toString();

//     if (floatingPointNumber.includes(".")) {
//       pieces = floatingPointNumber.split(".");
//       result = `${pieces[0]}.999`;
//       result = Number(result) * 1000;
//     } else {
//       result = num;
//     }
//   } else if (numLength === 5) {
//     floatingPointNumber = num / 10000;

//     floatingPointNumber = floatingPointNumber.toString();

//     console.log(num);

//     if (floatingPointNumber.includes(".")) {
//       pieces = floatingPointNumber.split(".");
//       result = `${pieces[0]}.9999`;
//       result = Number(result) * 10000;
//     } else {
//       result = num;
//     }
//   } else if (numLength === 6) {
//     floatingPointNumber = num / 100000;

//     floatingPointNumber = floatingPointNumber.toString();

//     if (floatingPointNumber.includes(".")) {
//       pieces = floatingPointNumber.split(".");
//       result = `${pieces[0]}.99999`;
//       result = Number(result) * 100000;
//     } else {
//       result = num;
//     }
//   } else if (numLength === 7) {
//     floatingPointNumber = num / 1000000;

//     floatingPointNumber = floatingPointNumber.toString();

//     if (floatingPointNumber.includes(".")) {
//       pieces = floatingPointNumber.split(".");
//       result = `${pieces[0]}.999999`;
//       result = Number(result) * 1000000;
//     } else {
//       result = num;
//     }
//   }

//   console.log(num, " => length : ", numLength, " => result: ", result);

//   return Number(result);
// };

const recalculateRanges = (ranges, totalCellCountPerRow) => {
  if (ranges.length === totalCellCountPerRow) {
    return ranges;
  }

  const finalRanges = [];

  const grades = roiMatrixConfig.headers.grades;
  const units = roiMatrixConfig.headers.units;

  let index = ranges.length - 1;
  let finalIndexes = totalCellCountPerRow - 1;

  while (index >= 0) {
    const range = ranges[index];

    if (!range) {
      // finalRanges.splice(finalIndexes--, 0, range);
      finalRanges[finalIndexes--] = range;
    } else {
      if (range.colspan > 1) {
        let colspan = range.colspan;
        const diff = (range.max - range.min) / colspan;

        let min = range.min;
        let max = diff + min;

        while (colspan > 0) {
          // finalRanges.splice(finalIndexes, 0, {
          //   max: Math.floor(max),
          //   min: min === 0 ? min : Math.ceil(min),
          //   maxLabel: formatNumber(max),
          //   minLabel: min === 0 ? "$0K" : formatNumber(min),
          //   colspan: 1,
          //   isActive: grades[finalIndexes].isActive,
          //   grades: {
          //     max: grades[finalIndexes].label,
          //     min: grades[finalIndexes].label,
          //   },
          //   units: {
          //     max: units[finalIndexes].label,
          //     min: units[finalIndexes].label,
          //   },
          // });

          finalRanges[finalIndexes] = {
            max: Math.floor(max),
            min: min === 0 ? min : Math.ceil(min),
            maxLabel: formatNumber(max),
            minLabel: min === 0 ? "$0K" : formatNumber(Math.ceil(min)),
            colspan: 1,
            isActive: grades[finalIndexes].isActive,
            grades: {
              max: grades[finalIndexes].label,
              min: grades[finalIndexes].label,
            },
            units: {
              max: units[finalIndexes].label,
              min: units[finalIndexes].label,
            },
          };

          //min = Math.ceil(max);

          //min = createMaxRangeWithDifference(max);
          min = Math.ceil(max);
          max = Math.floor(diff + max);

          finalIndexes--;
          colspan--;
        }
      } else {
        // finalRanges.splice(finalIndexes--, 0, range);
        finalRanges[finalIndexes--] = range;
      }
    }

    index--;
  }
  //console.log(finalRanges);
  //console.log(ranges.length);
  return finalRanges;
};

export const getRecalculatedRanges = () => {
  const totalCellsCount = roiMatrixConfig.headers.grades.length;

  const finalGroups = [];

  for (const group of roiMatrixConfig.groups) {
    finalGroups.push({
      ...group,
      ranges: recalculateRanges(group.ranges, totalCellsCount),
    });
  }

  return finalGroups;
};

export const getUnitSize = (arg) => {
  if (!arg) {
    return 0;
  } else if (arg && typeof arg.roiMatrix === "undefined") {
    return 0;
  } else if (
    arg &&
    typeof arg.roiMatrix !== "undefined" &&
    typeof arg.roiMatrix.unitSize !== "undefined"
  ) {
    return arg.roiMatrix.unitSize;
  } else {
    return 0;
  }
};

export const getFinalIndexes = (firstIndex, i, totalCellCount, ranges) => {
  let finalMinIndex = 0;
  let finalMaxIndex = 0;
  let count = 0;

  if (i === firstIndex) {
    finalMaxIndex = i;

    count = i + 1;

    while (count < totalCellCount) {
      if (typeof ranges[count] !== "undefined" && ranges[count]) {
        finalMinIndex = count;
        break;
      }
      count++;
    }
  } else if (i > firstIndex && i < totalCellCount - 1) {
    count = i - 1;

    while (count >= firstIndex) {
      if (typeof ranges[count] !== "undefined" && ranges[count]) {
        finalMaxIndex = count;
        break;
      }
      count--;
    }

    count = i + 1;

    while (count < totalCellCount) {
      if (typeof ranges[count] !== "undefined" && ranges[count]) {
        finalMinIndex = count;
        break;
      }
      count++;
    }
  } else if (i === totalCellCount - 1) {
    count = i - 1;

    while (count >= firstIndex) {
      if (typeof ranges[count] !== "undefined" && ranges[count]) {
        finalMaxIndex = count;
        break;
      }
      count--;
    }

    finalMinIndex = i;
  }

  return {
    finalMinIndex,
    finalMaxIndex,
  };
};

export const calculateBetSizeRoiRange = (unitSize, item, generatedRanges) => {
  const groups = generatedRanges;
  const totalCellsCount = roiMatrixConfig.headers.grades.length;

  let league = null;
  let firstIndex = -1;

  for (const group of groups) {
    if (group.label === item.leagueShortName) {
      league = group;
      break;
    }
  }

  if (!league) return null;

  const roiAway = getRawRoi(item, "away");
  const roiHome = getRawRoi(item, "home");

  const roi = roiAway ? roiAway : roiHome;

  //const roi = 1400000;

  for (let j = 0; j < league.ranges.length; j++) {
    if (league.ranges[j]) {
      firstIndex = j;
      break;
    }
  }

  //const roi = 10000000;

  // let finalMaxIndex = 0;
  // let finalMinIndex = 0;

  //console.log(league.ranges, totalCellsCount, roi);

  for (let i = 0; i < totalCellsCount; i++) {
    let res = null;

    if (i === firstIndex && roi >= league.ranges[i].max) {
      res = getFinalIndexes(firstIndex, i, totalCellsCount, league.ranges);

      return {
        roi,
        betSize: formatToCurrency(
          roiMatrixConfig.headers.units[i].label * unitSize
        ),
        unit: roiMatrixConfig.headers.units[i].label,
        grade: roiMatrixConfig.headers.grades[i].label,
        range: {
          ...league.ranges[i],
          max: league.ranges[i].max,
          min: league.ranges[i].min,
          //maxLabel: league.ranges[res.finalMaxIndex].maxLabel,

          maxLabel: "∞",
          minLabel: league.ranges[i].maxLabel,
          colspan: 1,
          isActive: true,
          grades: {
            min: roiMatrixConfig.headers.grades[i].label,
            max: roiMatrixConfig.headers.grades[i].label,
          },
          units: {
            min: roiMatrixConfig.headers.units[i].label,
            max: roiMatrixConfig.headers.units[i].label,
          },
        },
      };
    } else if (
      i === firstIndex &&
      league.ranges[i] &&
      roi >= league.ranges[i].min
    ) {
      res = getFinalIndexes(firstIndex, i, totalCellsCount, league.ranges);

      return {
        roi,
        betSize: formatToCurrency(
          roiMatrixConfig.headers.units[i].label * unitSize
        ),
        unit: roiMatrixConfig.headers.units[i].label,
        grade: roiMatrixConfig.headers.grades[i].label,
        range: {
          ...league.ranges[i],
          max: league.ranges[res.finalMaxIndex].max,
          min: league.ranges[res.finalMinIndex].min,
          //maxLabel: league.ranges[res.finalMaxIndex].maxLabel,

          maxLabel: "∞",
          minLabel: league.ranges[res.finalMinIndex].maxLabel,
          colspan: 1,
          isActive: true,
          grades: {
            min: roiMatrixConfig.headers.grades[res.finalMinIndex].label,
            max: roiMatrixConfig.headers.grades[res.finalMaxIndex].label,
          },
          units: {
            min: roiMatrixConfig.headers.units[res.finalMinIndex].label,
            max: roiMatrixConfig.headers.units[res.finalMaxIndex].label,
          },
        },
      };
    } else if (
      i === totalCellsCount - 1 &&
      league.ranges[i] &&
      roi >= league.ranges[i].min &&
      roi <= league.ranges[i].max
    ) {
      res = getFinalIndexes(firstIndex, i, totalCellsCount, league.ranges);

      return {
        roi,
        betSize: formatToCurrency(
          roiMatrixConfig.headers.units[i].label * unitSize
        ),
        unit: roiMatrixConfig.headers.units[i].label,
        grade: roiMatrixConfig.headers.grades[i].label,
        range: {
          ...league.ranges[i],
          max: league.ranges[res.finalMaxIndex].max,
          min: league.ranges[res.finalMinIndex].min,
          maxLabel: league.ranges[res.finalMaxIndex].minLabel,
          minLabel: league.ranges[res.finalMinIndex].minLabel,
          colspan: 1,
          isActive: true,
          grades: {
            min: roiMatrixConfig.headers.grades[res.finalMinIndex].label,
            max: roiMatrixConfig.headers.grades[res.finalMaxIndex].label,
          },
          units: {
            min: roiMatrixConfig.headers.units[res.finalMinIndex].label,
            max: roiMatrixConfig.headers.units[res.finalMaxIndex].label,
          },
        },
      };
    } else if (
      i > firstIndex &&
      league.ranges[i] &&
      roi >= league.ranges[i].min &&
      roi <= league.ranges[i].max
    ) {
      res = getFinalIndexes(firstIndex, i, totalCellsCount, league.ranges);

      return {
        roi,
        betSize: formatToCurrency(
          roiMatrixConfig.headers.units[i].label * unitSize
        ),
        unit: roiMatrixConfig.headers.units[i].label,
        grade: roiMatrixConfig.headers.grades[i].label,
        range: {
          ...league.ranges[i],
          max: league.ranges[res.finalMaxIndex].max,
          min: league.ranges[res.finalMinIndex].min,
          maxLabel: league.ranges[res.finalMaxIndex].minLabel,
          minLabel: league.ranges[res.finalMinIndex].maxLabel,
          colspan: 1,
          isActive: true,
          grades: {
            min: roiMatrixConfig.headers.grades[res.finalMinIndex].label,
            max: roiMatrixConfig.headers.grades[res.finalMaxIndex].label,
          },
          units: {
            min: roiMatrixConfig.headers.units[res.finalMinIndex].label,
            max: roiMatrixConfig.headers.units[res.finalMaxIndex].label,
          },
        },
      };
    }
  }

  //   if (i === 0) {
  //     if (league.ranges[i] && roi >= league.ranges[i].min) {
  //       res = getFinalIndexes(firstIndex, i, totalCellsCount, league.ranges);

  //       return {
  //         roi,
  //         betSize: formatToCurrency(
  //           roiMatrixConfig.headers.units[i].label * unitSize
  //         ),
  //         unit: roiMatrixConfig.headers.units[i].label,
  //         grade: roiMatrixConfig.headers.grades[i].label,
  //         range: {
  //           ...league.ranges[i],
  //           max: league.ranges[res.finalMaxIndex].max,
  //           min: league.ranges[res.finalMinIndex].min,
  //           maxLabel: league.ranges[res.finalMaxIndex].maxLabel,
  //           minLabel: league.ranges[res.finalMinIndex].minLabel,
  //           colspan: 1,
  //           isActive: true,
  //           grades: {
  //             min: roiMatrixConfig.headers.grades[res.finalMinIndex].label,
  //             max: roiMatrixConfig.headers.grades[res.finalMaxIndex].label,
  //           },
  //           units: {
  //             min: roiMatrixConfig.headers.units[res.finalMinIndex].label,
  //             max: roiMatrixConfig.headers.units[res.finalMaxIndex].label,
  //           },
  //         },
  //       };
  //     }
  //   }
  //   else if (
  //     i > firstIndex &&
  //     league.ranges[i] &&
  //     roi >= league.ranges[i].min &&
  //     roi <= league.ranges[i].max
  //   ) {
  //     res = getFinalIndexes(firstIndex, i, totalCellsCount, league.ranges);

  //     return {
  //       roi,
  //       betSize: formatToCurrency(
  //         roiMatrixConfig.headers.units[i].label * unitSize
  //       ),
  //       unit: roiMatrixConfig.headers.units[i].label,
  //       grade: roiMatrixConfig.headers.grades[i].label,
  //       range: {
  //         ...league.ranges[i],
  //         max: league.ranges[res.finalMaxIndex].max,
  //         min: league.ranges[res.finalMinIndex].min,
  //         maxLabel: league.ranges[res.finalMaxIndex].maxLabel,
  //         minLabel: league.ranges[res.finalMinIndex].minLabel,
  //         colspan: 1,
  //         isActive: true,
  //         grades: {
  //           min: roiMatrixConfig.headers.grades[res.finalMinIndex].label,
  //           max: roiMatrixConfig.headers.grades[res.finalMaxIndex].label,
  //         },
  //         units: {
  //           min: roiMatrixConfig.headers.units[res.finalMinIndex].label,
  //           max: roiMatrixConfig.headers.units[res.finalMaxIndex].label,
  //         },
  //       },
  //     };
  //   }
  // }

  // old versions
  // for (let i = totalCellsCount - 1; i >= 0; i--) {
  //   if (i === totalCellsCount - 1) {
  //     if (league.ranges[i] && roi >= league.ranges[i].min) {
  //       return {
  //         roi,
  //         betSize: formatToCurrency(
  //           roiMatrixConfig.headers.units[i].label * unitSize
  //         ),
  //         unit: roiMatrixConfig.headers.units[i].label,
  //         grade: roiMatrixConfig.headers.grades[i].label,
  //         range: {
  //           ...league.ranges[i],
  //         },
  //       };
  //     }
  //   }
  //   if (
  //     league.ranges[i] &&
  //     roi >= league.ranges[i].min &&
  //     roi <= league.ranges[i].max
  //   ) {
  //     return {
  //       roi,
  //       betSize: formatToCurrency(
  //         roiMatrixConfig.headers.units[i].label * unitSize
  //       ),
  //       unit: roiMatrixConfig.headers.units[i].label,
  //       grade: roiMatrixConfig.headers.grades[i].label,
  //       range: {
  //         ...league.ranges[i],
  //       },
  //     };
  //   }
  // }

  return null;
};

export const getMatchUnitGrade = (match, generatedRanges) => {
  const totalCellsCount = roiMatrixConfig.headers.grades.length;

  let unit = "";
  let grade = "";
  let league = null;
  let currentIndex = -1;

  const roiAway = getRawRoi(match, "away");
  const roiHome = getRawRoi(match, "home");

  const roi = roiAway ? roiAway : roiHome;

  if (!roi) {
    return { unit, grade };
  }

  for (const group of generatedRanges) {
    if (group.label === match.leagueShortName) {
      league = group;
      break;
    }
  }

  if (!league || !roi) {
    return { unit, grade };
  }

  let firstIndex = null;

  for (let i = 0; i < totalCellsCount; i++) {
    if (league.ranges[i]) {
      firstIndex = i;
      break;
    }
  }

  for (let i = 0; i < totalCellsCount; i++) {
    if (
      i === firstIndex &&
      league.ranges[i] &&
      (roi >= league.ranges[i].max ||
        (roi >= league.ranges[i].min && roi <= league.ranges[i].max))
    ) {
      currentIndex = i;
      break;
    } else if (
      league.ranges[i] &&
      roi >= league.ranges[i].min &&
      roi <= league.ranges[i].max
    ) {
      currentIndex = i;
      break;
    }
  }

  if (currentIndex >= 0 && currentIndex < totalCellsCount) {
    unit = roiMatrixConfig.headers.units[currentIndex].label;
    grade = roiMatrixConfig.headers.grades[currentIndex].label;
  }

  return { unit, grade };
};

export const getRowGrade = (match, grade, type) => {
  if (type === "away" && match.roi) {
    if (
      match.consensus?.totalCashAwayPercentageValue.includes("u") ||
      match.consensus?.totalCashAwayPercentageValue.includes("o")
    ) {
      return null;
    }

    return grade;
  } else if (type === "home" && match.roi) {
    if (
      match.consensus?.totalCashHomePercentageValue.includes("u") ||
      match.consensus?.totalCashHomePercentageValue.includes("o")
    ) {
      return null;
    }

    return grade;
  }
};

export const getTimeDifference = (timestamp, diffType = "hour") => {
  let localTime = dayjs().tz(TIMEZONE);
  let serverTime = dayjs(timestamp).tz(TIMEZONE);

  const diff = serverTime.diff(localTime, diffType, true);

  return diff;
};

export const getGroupMatch = (match, group) => {
  let diff = null;

  if (group === "watching") {
    diff = getTimeDifference(match.startDateTime, "minute");

    return diff > 0 ? match : null;
  } else if (group === "current") {
    if (
      !match.startDateTime &&
      match.scores &&
      !["Final", "CNCLD"].includes(match.scores.homeStatus)
    ) {
      return match;
    }

    diff = getTimeDifference(match.startDateTime, "minute");

    return diff <= 0 &&
      (!match.scores ||
        (match.scores && !["Final", "CNCLD"].includes(match.scores.homeStatus)))
      ? match
      : null;
  } else if (group === "final") {
    return match.scores && ["Final", "CNCLD"].includes(match.scores.homeStatus)
      ? match
      : null;
  }

  return null;
};

export const calculateGamesForLeftColumn = (list, sport) => {
  let games = 0;

  for (const l of list) {
    if (l) {
      for (const i of l.items) {
        if (sport === i.label) {
          games += i.games;
        }
      }
    }
  }

  return games;
};

export const calculateTotalGamesForLeftColumn = (list) => {
  let games = 0;
  for (const l of list) {
    games += l.games;
  }
  return games;
};

export const calculateCreditsByColumn = (list, index) => {
  let credits = 0;

  for (const l of list) {
    if (l[index] && typeof l[index].credits !== "undefined") {
      credits += l[index].credits;
    }
  }
  return credits;
};

export const calculateNthRowCreditsForLeftColumn = (list) => {
  let credits = 0;
  for (const l of list) {
    if (l) {
      credits += l.credits;
    }
  }

  return credits;
};

export const calculateUnitSize = (list) => {
  let costs = 0;

  for (const cost of list) {
    costs += cost;
  }
  return costs;
};

export const createUpdatedAtTimestamp = () => {
  return dayjs().set("minute", 1);
};

export const isUpdatable = (date) => {
  if (!date) return true;

  const now = dayjs();

  const diff = now.diff(date, "minute");

  console.log(diff, date, now);

  return diff <= 0 ? true : false;
};

export const strToNum = (arg = null) => {
  if (!arg) return 0;

  if (arg.includes("M")) {
    const num = parseFloat(arg.replace(/^\D+/g, ""));
    return num * 1000000;
  } else if (arg.includes("K")) {
    const num = parseFloat(arg.replace(/^\D+/g, ""));
    return num * 1000;
  }
  return parseFloat(arg.replace(/^\D+/g, ""));
};
