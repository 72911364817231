import { keyframes } from "@emotion/react";

export const COLORS = {
  extraDark: "#141414",
  dark: "#191a24",
  dark1: "#18181d",
  semiDark: "#4c4e69",
  medium: "#20222f",
  medium1: "#313344",
  light: "#323547",
  lightTransparent: "#32354757",
  blue: "#5aa5fe",
  silver: "#d1d3e0",
  silver2: "#c3c3c6",
  silver3: "#9f9fa3",
  yellow: "#dfc650",
  yellow1: "#ebcb62",
  orange: "#f49a41",
  purple: "#6672cc",
  gray: "#585a6f",
  gray2: "#303d53",
  gray3: "#7e7983",
  gray4: "#6b6872",
  //gray5: "#908f90",
  gray5: "#adadadfa",
  gray6: "#9496a3",
  gray7: "#56565c",
  gray8: "#7a7a7f",

  red: "#be5351",
  transparentWhite: "#ffffffb3",
  border: {
    gray: "#2a2c3a",
    gray1: "#464957",
    gray2: "#2b2c39",
  },
  fonts: {
    gray: "#9597A3",
    lightGray: "#858693",
    white: "#fcfcfc",
    whitishGray: "#d3d3df",
  },
  whiteWithOpacity: (percentage) => {
    const finalPercentage = percentage / 100;

    return `rgba(255,255,255,${finalPercentage}) !important`;
  },
  backgroundWhiteWithOpacity: (percentage) => {
    const finalPercentage = percentage / 100;

    return {
      backgroundColor: `rgba(255,255,255,${finalPercentage}) !important`,
    };
  },
  textWhiteWithOpacity: (percentage) => {
    const finalPercentage = percentage / 100;

    return {
      color: `rgba(255,255,255,${finalPercentage}) !important`,
    };
  },
};

export const menuItemLabel = {
  size: "1rem",
  weight: 400,
  letterSpacing: "0.03rem",
};

export const dropdownMenuFontSize = {
  fontSize: ".9rem",
};

export const blinkingAnimation = keyframes`
0%,
49% {
   background: linear-gradient(to right, rgba(255,255,255,0), rgba(20,20,21,1))
}
50%,
100% {
   background: linear-gradient(to right, transparent, rgba(255,255,255,0), rgba(100,37,39,0.5) )
   
}
`;
//1.133333333333333
export const table = {
  lg: {
    header: {
      fontSize: "1.0625rem",
      fontWeight: "500 !important",
      textTransform: "uppercase",
      letterSpacing: menuItemLabel.letterSpacing,
      borderBottom: `solid 1px ${COLORS.border.gray}`,
      color: COLORS.fonts.white,
      p: "0 10px",
      height: "65px",
      transition: "all .3s",
      position: "relative",
      "& > sup": {
        position: "absolute",
        fontSize: "10px",
        ml: "3px",
        top: "15px",
      },
      "@media(max-width:1535px)": {
        fontSize: "0.9375rem",
        transition: "all .3s",
      },
      "&:first-of-type": {
        pl: "40px",
        "@media(max-width:1199px)": {
          pl: "20px",
        },
      },
      "&:last-of-type": {
        pr: "53px",
        "@media(max-width:1199px)": {
          pr: "33px",
        },
      },
      "&:nth-of-type(5),&:nth-of-type(6),&:nth-of-type(7)": {
        pr: "23px",
      },
      "@media(max-width:1199px)": {
        //fontSize: "1rem",
        transition: "all .3s",
      },
      "@media(max-width:719px)": {
        //fontSize: ".8rem",
        transition: "all .3s",
        fontWeight: 600,
      },
      "@media(max-width:599px)": {
        height: "46px",
      },
      "&:nth-of-type(1)": {
        width: "140px",
        minWidth: "140px",
        // border: "solid 1px red",
        // "@media(max-width:1440px)": {
        //   width: "135px",
        //   minWidth: "135px",
        // },
      },
      "&:nth-of-type(2)": {
        width: "6%",
        // border: "solid 1px red",
        "@media(max-width:1100px)": {
          width: "80px",
          minWidth: "80px",
        },
      },
      "&:nth-of-type(3)": {
        width: "12%",
        // border: "solid 1px red",
        "@media(max-width:1100px)": {
          width: "150px",
          minWidth: "150px",
        },
      },
      "&:nth-of-type(4)": {
        width: "100px",
        minWidth: "100px",
        textAlign: "right",
        // border: "solid 1px red",
        "@media(max-width:1100px)": {
          width: "100px",
          minWidth: "100px",
        },
      },
      "&:nth-of-type(5)": {
        width: "150px",
        minWidth: "100px",
        // border: "solid 1px red",
        pr: "30px !important",
        // "@media(max-width:1200px)": {
        //   width: "100px",
        //   minWidth: "100px",
        // },
      },
      "&:nth-of-type(6),:nth-of-type(7),:nth-of-type(8)": {
        width: "18%",
        // border: "solid 1px red",
      },
    },
    innerTable: {
      borderCollapse: "initial",
      border: "solid 1px transparent",
      position: "relative",
      "& tr > td": {
        //borderBottom: `dashed 1px ${COLORS.border.gray}`,
        //borderBottom: "dashed 1px transparent",
        fontSize: "1rem",
        letterSpacing: menuItemLabel.letterSpacing,
        color: COLORS.fonts.gray,
        p: "0 10px",
        height: "61px",
        transition: "all .3s",
        "@media(max-width:1535px)": {
          fontSize: "0.8125rem",
        },
        "@media(max-width:1199px)": {
          //fontSize: "0.9rem",
          transition: "all .3s",
          height: "50px",
        },
      },
      "& tr:nth-of-type(1) > td": {
        borderBottom: "dashed 1px #23232e",
      },
      "& tr:nth-of-type(2) > td": {
        borderBottom: "dashed 1px #2b2c39",
      },
      "& tr:last-of-type > td": {
        borderBottom: 0,
      },
      "& tr > td:first-of-type": {
        pl: "40px",
        "@media(max-width:1199px)": {
          pl: "20px",
        },
      },
      "& tr > td:last-of-type": {
        textAlign: "right",
        pr: "40px",
        "@media(max-width:1199px)": {
          pr: "20px",
        },
      },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(1), tbody > tr:nth-of-type(2) td:nth-of-type(1)":
        {
          width: "140px",
          minWidth: "140px",
          // border: "solid 1px red",
          // "@media(max-width:1440px)": {
          //   width: "135px",
          //   minWidth: "135px",
          // },
        },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(2), tbody > tr:nth-of-type(2) td:nth-of-type(2)":
        {
          width: "6%",
          // border: "solid 1px red",
          "@media(max-width:1100px)": {
            width: "80px",
            minWidth: "80px",
          },
        },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(3), tbody > tr:nth-of-type(2) td:nth-of-type(3)":
        {
          width: "12%",
          // border: "solid 1px red",

          "@media(max-width:1100px)": {
            width: "150px",
            minWidth: "150px",
          },
        },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(4), tbody > tr:nth-of-type(2) td:nth-of-type(4)":
        {
          width: "100px",
          minWidth: "100px",
          // border: "solid 1px red",
          textAlign: "right",
          "@media(max-width:1100px)": {
            width: "100px",
            minWidth: "100px",
          },
        },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(5), tbody > tr:nth-of-type(2) td:nth-of-type(5)":
        {
          width: "150px",
          minWidth: "100px",
          // border: "solid 1px red",
          // "@media(max-width:1200px)": {
          //   width: "100px",
          //   minWidth: "100px",
          // },
        },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(6),  tbody > tr:nth-of-type(1) td:nth-of-type(7), tbody > tr:nth-of-type(1) td:nth-of-type(8), tbody > tr:nth-of-type(2) td:nth-of-type(6),  tbody > tr:nth-of-type(2) td:nth-of-type(7), tbody > tr:nth-of-type(2) td:nth-of-type(8)":
        {
          width: "18%",
          // border: "solid 1px red",
        },
    },
  },
  md: {
    header: {
      textTransform: "uppercase",
      fontSize: "0.9375rem",
      fontWeight: 500,
      letterSpacing: menuItemLabel.letterSpacing,
      borderBottom: `solid 1px ${COLORS.border.gray}`,
      color: COLORS.fonts.white,
      p: "0 10px",
      height: "65px",
      transition: "all .3s",
      position: "relative",
      "& > sup": {
        position: "absolute",
        fontSize: "10px",
        ml: "3px",
        top: "15px",
      },
      "@media(max-width:979px)": {
        fontSize: "0.84375rem",
        transition: "all .3s",
      },
      "&:first-of-type": {
        pl: "40px",
        "@media(max-width:1199px)": {
          pl: "20px",
          transition: "all .3s",
        },
      },
      "&:last-of-type": {
        pr: "33px !important",
        "@media(max-width:1199px)": {
          pr: "33px",
          transition: "all .3s",
        },
      },
      "&:nth-of-type(1)": {
        width: "100px !important",
        // border: "solid 1px red",
      },
      "&:nth-of-type(2)": {
        width: "125px",
        minWidth: "125px",
        // border: "solid 1px red",
      },
      "&:nth-of-type(3)": {
        width: "95px",
        minWidth: "95px",
        textAlign: "right",
        pr: "16px !important",
        // border: "solid 1px red",
      },
      "&:nth-of-type(4),:nth-of-type(5),:nth-of-type(6)": {
        width: "20%",
        // border: "solid 1px red",
        pr: "20px",
      },
    },
    innerTable: {
      border: "solid 1px transparent",
      position: "relative",
      borderCollapse: "initial",
      "& tr > td": {
        //borderBottom: `dashed 1px ${COLORS.border.gray}`,
        fontSize: "0.8125rem",
        letterSpacing: menuItemLabel.letterSpacing,
        color: COLORS.fonts.gray,
        p: "0 10px",
        height: "61px",
        transition: "all .3s",
        "@media(max-width:979px)": {
          fontSize: "0.73125rem",
          transition: "all .3s",
          height: "50px",
        },
      },
      "& tr:nth-of-type(1) > td": {
        borderBottom: "dashed 1px #23232e",
      },
      "& tr:nth-of-type(2) > td": {
        borderBottom: "dashed 1px #2b2c39",
      },
      "& tr:last-of-type > td": {
        borderBottom: 0,
      },
      "& tr > td:first-of-type": {
        pl: "40px",
        "@media(max-width:1199px)": {
          pl: "20px",
          transition: "all .3s",
        },
      },
      "& tr > td:last-of-type": {
        textAlign: "right",
        pr: "40px",
        "@media(max-width:1199px)": {
          pr: "20px",
          transition: "all .3s",
        },
      },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(1), tbody > tr:nth-of-type(2) td:nth-of-type(1)":
        {
          width: "100px !important",
          // border: "solid 1px red",
        },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(2), tbody > tr:nth-of-type(2) td:nth-of-type(2)":
        {
          width: "125px",
          minWidth: "125px",
          // border: "solid 1px red",
        },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(3), tbody > tr:nth-of-type(2) td:nth-of-type(3)":
        {
          width: "95px",
          minWidth: "95px",
          textAlign: "right",
          pr: "15px !important",
          // border: "solid 1px red",
        },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(4),  tbody > tr:nth-of-type(1) td:nth-of-type(5), tbody > tr:nth-of-type(1) td:nth-of-type(6), tbody > tr:nth-of-type(2) td:nth-of-type(4),  tbody > tr:nth-of-type(2) td:nth-of-type(5), tbody > tr:nth-of-type(2) td:nth-of-type(6)":
        {
          width: "20%",
          // border: "solid 1px red",
        },
    },
  },
  sm: {
    header: {
      fontSize: "0.84375rem",
      fontWeight: "500 !important",
      textTransform: "uppercase",
      letterSpacing: menuItemLabel.letterSpacing,
      borderBottom: `solid 1px ${COLORS.border.gray}`,
      color: COLORS.fonts.white,
      p: "0 10px",
      height: "65px",
      transition: "all .3s",
      position: "relative",
      "& > sup": {
        position: "absolute",
        fontSize: "6px",
        ml: "3px",
        top: "15px",
        "@media(max-width:599px)": {
          top: "7px",
          fontSize: "6px",
        },
      },
      "@media(max-width:599px)": {
        height: "46px",
        fontSize: "0.6875rem",
        transition: "all .3s",
      },
      "@media(max-width:399px)": {
        fontSize: "0.625rem",
        transition: "all .3s",
      },

      "&:first-of-type": {
        pl: "20px",
        "@media(max-width:499px)": {
          pl: "10px",
        },
      },
      "&:last-of-type": {
        pr: "33px ",
        "@media(max-width:499px)": {
          pr: "20px !important",
          transition: "all .3s",
        },
      },
      "&:not(first-of-type):not(last-of-type)": {
        "@media(max-width:460px)": {
          px: "5px",
        },
      },
      "&:nth-of-type(1)": {
        width: "70px !important",
        minWidth: "60px !important",
        // border: "solid 1px red",
        // "@media(max-width:599px)": {
        //   minWidth: "80px",
        // },
        "@media(max-width:430px)": {
          //minWidth: "60px",
        },
      },
      "&:nth-of-type(2)": {
        width: "75px",
        // border: "solid 1px red",
        "@media(max-width:599px)": {
          width: "55px",
          minWidth: "55px",
        },
        "@media(max-width:430px)": {
          width: "45px",
          minWidth: "45px",
        },
      },
      "&:nth-of-type(3)": {
        width: "80px",
        textAlign: "right",
        // border: "solid 1px red",
        pr: "15px",
        "@media(max-width:599px)": {
          width: "50px",
          minWidth: "50px",
        },
      },
      "&:nth-of-type(4),:nth-of-type(5),:nth-of-type(6)": {
        width: "20%",
        // border: "solid 1px red",
        pr: "20px",
        "@media(max-width:599px)": {
          width: "22%",
        },
        "@media(max-width:499px)": {
          pr: "15px",
          pl: "5px",
        },
      },
    },
    innerTable: {
      border: "solid 1px transparent",
      position: "relative",
      borderCollapse: "initial",
      "& tr > td": {
        //borderBottom: `dashed 1px ${COLORS.border.gray}`,
        borderBottom: 0,
        fontSize: "0.73125rem",
        letterSpacing: menuItemLabel.letterSpacing,
        color: COLORS.fonts.gray,
        p: "0 10px",
        height: "61px",
        transition: "all .3s",

        "@media(max-width:599px)": {
          fontSize: "0.5625rem ",
          fontWeight: "500",
        },
      },
      "& tr:nth-of-type(1) > td": {
        borderBottom: "dashed 1px #23232e",
      },
      "& tr:nth-of-type(2) > td": {
        borderBottom: "dashed 1px #2b2c39",
      },
      "& tr td:first-of-type": {
        pl: "20px",
        "@media(max-width:499px)": {
          pl: "10px",
          transition: "all .3s",
        },
      },
      "& tr td:last-of-type": {
        pr: "33px ",
        "@media(max-width:499px)": {
          pr: "11px !important",
          transition: "all .3s",
        },
      },
      "& tr td:not(first-of-type):not(last-of-type)": {
        "@media(max-width:499px)": {
          px: "5px",
        },
      },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(1), tbody > tr:nth-of-type(2) td:nth-of-type(1)":
        {
          width: "70px !important",
          minWidth: "60px !important",
          // border: "solid 1px red",

          "@media(max-width:430px)": {
            minWidth: "60px",
            fontSize: "0.5rem !important",
          },
        },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(2), tbody > tr:nth-of-type(2) td:nth-of-type(2)":
        {
          width: "75px",
          // border: "solid 1px red",
          "@media(max-width:599px)": {
            width: "55px",
            minWidth: "55px",
          },
          "@media(max-width:430px)": {
            width: "45px",
            minWidth: "45px",
          },
        },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(3), tbody > tr:nth-of-type(2) td:nth-of-type(3)":
        {
          width: "80px",
          textAlign: "right",
          pr: "15px",
          // border: "solid 1px red",
          "@media(max-width:599px)": {
            width: "50px",
            minWidth: "50px",
          },
        },
      "& tbody > tr:nth-of-type(1) td:nth-of-type(4),  tbody > tr:nth-of-type(1) td:nth-of-type(5), tbody > tr:nth-of-type(1) td:nth-of-type(6), tbody > tr:nth-of-type(2) td:nth-of-type(4),  tbody > tr:nth-of-type(2) td:nth-of-type(5), tbody > tr:nth-of-type(2) td:nth-of-type(6)":
        {
          width: "20%",
          pr: "10px",
          // border: "solid 1px red",
          "@media(max-width:599px)": {
            width: "22%",
          },
          "@media(max-width:499px)": {
            //fontSize: "0.5rem !important",
            pr: "7px",
          },
        },
    },
  },
  accordion: {
    m: "0 !important",
  },
  rowSpanLabel: {
    fontSize: "1.275rem",
    fontWeight: 600,
    color: COLORS.fonts.white,
    transition: "all .3s",
    "@media(max-width:1535px)": {
      fontSize: "1.125rem",
      transition: "all .3s",
    },
    "@media(max-width:979px)": {
      fontSize: "1.0125rem",
      transition: "all .3s",
    },
    "@media(max-width:599px)": {
      fontSize: "0.6875rem",
      transition: "all .3s",
    },
  },
  tableContainer: {
    overflowX: "initial",
  },
  betCell: {
    pr: "20px !important",
    borderRight: `solid 1px ${COLORS.border.gray}`,
    background:
      "linear-gradient(to right, rgba(255,255,255,0), rgba(20,20,21,1))",
    "@media(max-width:979px)": {
      pr: "15px !important",
    },
  },
  outerAccordionHeadline: {
    fontSize: "1rem",
    fontWeight: "600",
  },
  innerAccordionHeadline: {
    fontSize: "1.0625rem",
    fontWeight: "600",
    textTransform: "uppercase",
  },

  heading: {
    size: "1.2rem",
    weight: 600,
    icon: {
      size: 15,
    },
  },
  cellText: {
    size: "1.2rem",
    weight: 500,
  },
  leftSpacing: "40px",
  rightSpacing: "40px",
  letterSpacing: "0.03rem",
};
